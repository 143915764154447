<template>
  <b-row class="px-33 py-3 border-top">
    <b-col
      cols="12"
      md="3"
      class="mb-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Nama di struk
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        {{ merchant.name_in_invoice || '-' }}
      </h6>
    </b-col>
    <b-col
      cols="12"
      md="6"
      class="mb-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Nama alias
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        {{ merchant.alias_name || '-' }}
      </h6>
    </b-col>
    <!-- <b-col
      cols="12"
      md="12"
      class="mb-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Alamat alias
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        {{ merchant.alias_address || '-' }}
      </h6>
    </b-col> -->
    <b-col
      cols="12"
      md="12"
      class="mb-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Kota
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        {{ merchant.city || '-' }}
      </h6>
    </b-col>
    <b-col
      cols="12"
      md="12"
      class="mb-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Kode Pos
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        {{ merchant.postal_code || '-' }}
      </h6>
    </b-col>
    <b-col
      cols="12"
      md="12"
      class="mb-2 custom__form--input"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Pin Lokasi di Peta
      </label>
      <b-col
        class="px-0"
        cols="12"
        md="12"
        lg="6"
      >
        <GeolocationSelectorMap
          :key="2"
          v-model="selectedLocation"
          :default-location="currentLocation"
        />
        <!-- <Maps
          :lattitude="merchant.address_latitude"
          :longtitude="merchant.address_longitude"
          :draggable-map="true"
          :zoom-map="20"
        /> -->
      </b-col>
    </b-col>
    <b-col
      cols="12"
      md="12"
      class="mt-2"
    >
      <label class="text-dark text-darken-4 fw-bold-500 size14">
        Daftar Cabang
      </label>
      <h6 class="text-dark size18 fw-bold-600">
        <ul style="padding: 0;list-style-position: inside">
          <li v-for="(branch, index) in branches" :key="index">
            {{ branch.name }}
          </li>
        </ul>
      </h6>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'

import Maps from '@/components/Maps.vue'
import GeolocationSelectorMap from '@/components/GeolocationSelectorMap.vue'

export default {
  components: {
    BRow,
    BCol,
    Maps,
    GeolocationSelectorMap,
  },
  props: {
    merchants: {
      type: Object,
    },
    branches: {
      type: Object,
    },
  },
  watch: {
    merchants(value) {
      this.merchant = value
    },
  },
  data() {
    return {
      merchant: Object,
      selectedLocation: {},
      currentLocation: {
        lat: this.$store.state.profile.merchant.address_latitude || '',
        lng: this.$store.state.profile.merchant.address_longitude || '',
      },
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    border-radius: 8px;
    height: 350px;
  }
}
</style>
