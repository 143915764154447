<template>
  <b-row v-if="isLoading == false">
    <b-col cols="12">
      <h4 class="text-black-2 fw-bold-700 size16 mb-1">
        Daftar Rekening ({{ result.total }})
      </h4>
    </b-col>
    <b-col v-for="(item, index) in result.data" :key="index" cols="12" md="6">
      <b-card class="px-16 py-16 border-12" no-body>
        <div class="d-flex flex-column">
          <div class="d-flex mb-2">
            <b-img
              width="120"
              height="72"
              style="object-fit: cover"
              class="border-8"
              :src="item.bank.logo_url"
            />
            <div class="ml-1">
              <h6 class="text-dark text-darken-5 fw-bold-400 size14">
                {{ item.account_type }}
              </h6>
              <h5 class="text-black-2 fw-bold-800 size16">
                {{ item.account_number }}
              </h5>
              <span class="text-dark fw-bold-500 size14 mb-0"
                >a.n. {{ item.owner_name }}</span
              >
            </div>
          </div>
          <div class="d-flex">
            <b-button
              v-if="checkPermission('update rekening')"
              class="bg-white mr-1 px-3 btn-block"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)"
              @click="editItem(item)"
            >
              <b-img :src="require('@/assets/images/icons/Process2.svg')" />
              Ubah
            </b-button>
            <b-button
              v-if="checkPermission('delete rekening')"
              class="border-0 border-8 btn-delete bg-pink p-1"
              @click="deleteData(item.uuid)"
            >
              <feather-icon icon="Trash2Icon" size="18" class="text-danger" />
            </b-button>
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" md="6">
      <b-card
        v-if="checkPermission('add rekening')"
        class="px-3 py-18 border-12"
        style="cursor: pointer"
        no-body
        @click="$bvModal.show('modal-add-rekening')"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-between py-3"
        >
          <h6>
            <feather-icon icon="PlusIcon" size="28" />
          </h6>
          <h6 class="text-dark fw-bold-800 mb-0">Tambah rekening baru</h6>
        </div>
      </b-card>
    </b-col>
    <ModalAddRekening
      :form-data="formPayload"
      :edit-id="editId"
      :edit-item="editItem"
      :clean-up-form="cleanUpForm"
      :options="options"
      :get-data-bank-accounts="getDataBankAccounts"
      @getPayload="getPayload"
    />
  </b-row>
</template>

<script>
import { BRow, BButton, BImg, VBToggle, BCol, BCard } from "bootstrap-vue";

import { mapActions } from "vuex";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import ModalAddRekening from "@/components/Master/Toko/Modal/ModalAddRekening.vue";

export default {
  title() {
    return "Master Toko";
  },
  components: {
    BRow,
    BButton,
    BImg,
    BCol,
    BCard,
    ModalAddRekening,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      editId: null,
      isLoading: true,
      result: {},
      options: [],
      formPayload: {
        bank_uuid: "",
        owner_name: "",
        account_number: "",
        account_type: "",
      },
      bank: {},
      messages: "",
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  computed: {},
  created() {
    this.getDataBankAccounts();
  },
  methods: {
    ...mapActions("bankAccounts", ["getData"]),
    getPayload(value) {
      this.formPayload = value;
    },
    async getDataBankAccounts() {
      this.isLoading = true;
      await this.getData({
        params: "",
      })
        .then((result) => {
          this.result = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    cleanUpForm() {
      this.editId = null;
      this.bank = {
        logo: "",
        name: "",
      };
      this.formPayload = {
        bank_uuid: "",
        owner_name: "",
        account_number: "",
        account_type: "",
      };
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        bank_uuid: item.bank.uuid,
        owner_name: item.owner_name,
        account_number: item.account_number,
        account_type: item.account_type,
      };
      this.$bvModal.show("modal-add-rekening");
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus Rekening ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("bankAccounts/deleteData", `/${uuid}`)
            .then(() => {
              this.getDataBankAccounts();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Akun bank berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>
